<template>
	<!-- News With Sidebar Start -->
	<div class="container-fluid mt-5 pt-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <!-- News Detail Start -->
                    <div class="position-relative mb-3">
						<carousel :per-page="1" :autoplay="true"  :loop="true" :paginationEnabled="false">
							<slide v-for="(image,index) in post.images" :key="index">
								<!-- <img style="max-height:420px" :src="$urlpath+image" alt=""> -->
								<img :src="$urlpath+image"  style="max-height:420px; object-fit: cover;">
							</slide>
						</carousel>
                        
                        <div class="bg-white border border-top-0 p-4">
                            <div class="mb-3">
								<router-link class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" 
								:to="'/category/'+post.categ_id">{{post.categ_name}}</router-link>
                                <a class="text-body" href="">{{post.postdate|calendar}}</a>
                            </div>
                            <h1 class="mb-3 text-secondary text-uppercase font-weight-bold">{{post.title}}</h1>
							<p v-html="post.content"></p>
                            
                        </div>
                        <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                            <div class="d-flex align-items-center">
                                <img class="rounded-circle mr-2" :src="$profilepath+post.profile" width="25" height="25" alt="">
                                <span>{{post.author}}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span v-on:click="dislike()" v-if="dislikes==1" class="ml-3 like"><i class="fas fa-thumbs-down mr-2"></i>{{dislikes}}</span>
                                <span v-on:click="dislike()" v-else class="ml-3 like"><i class="far fa-thumbs-down mr-2"></i>{{dislikes}}</span>
                                <span v-on:click="like()" v-if="likes==1" class="ml-3 like"><i class="fas fa-thumbs-up mr-2"></i>{{likes}}</span>
                                <span v-on:click="like()" v-else class="ml-3 like"><i class="far fa-thumbs-up mr-2"></i>{{likes}}</span>
                                <span class="ml-3 like"><i class="far fa-eye mr-2"></i>{{post.view}}</span>
                                <span class="ml-3 like"><i class="far fa-comment mr-2"></i>123</span>
                            </div>
                            
                        </div>
                        <div class="d-flex align-items-center bg-white p-4">
                            <share-it icons
                            :text="'*'+post.title+'*\n'" 
                            :url="$postUrl+'single-post/'+post.id"
                            description="this is description."
                            :height="600" :width="600"
                            image="https://admin.indiakhabar.co.in/images/posts/av.jpg"
                            /> 
                           
                        </div>
                        <div class="share-network-list">
                            <ShareNetwork
                                v-for="network in networks"
                                :network="network.network"
                                :key="network.network"
                                :style="{backgroundColor: network.color}"
                                :url="$postUrl+'single-post/'+post.id"
                                :title="'*'+post.title+'*'"
                                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                               
                            >
                                <i :class="network.icon"></i>
                                <span>{{ network.name }}</span>
                            </ShareNetwork>
                            
                        </div>  
                    </div>
                    <!-- News Detail End -->

                    
                    <!-- Comment Form Start -->
                    <div class="mb-3">
                        <div class="section-title mb-0">
                            <h4 class="m-0 text-uppercase font-weight-bold">Leave a comment</h4>
                        </div>
                        <div class="bg-white border border-top-0 p-4">
                            <form>
                                <div class="form-row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="name">Name *</label>
                                            <input type="text" class="form-control" id="name">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="email">Email *</label>
                                            <input type="email" class="form-control" id="email">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="website">Website</label>
                                    <input type="url" class="form-control" id="website">
                                </div>

                                <div class="form-group">
                                    <label for="message">Message *</label>
                                    <textarea id="message" cols="30" rows="5" class="form-control"></textarea>
                                </div>
                                <div class="form-group mb-0">
                                    <input type="submit" value="Leave a comment"
                                        class="btn btn-primary font-weight-semi-bold py-2 px-3">
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Comment Form End -->
                </div>

                <side-component/>
            </div>
        </div>
    </div>
    <!-- News With Sidebar End -->
</template>

<script>
import Mainaxios from '../Api'
import SideComponent from '../components/SideComponent.vue';
export default {
  components: { SideComponent, },
  name: "Banner",
  props: {
    msg: String,
  },
  metaInfo: {
    title: 'Default App Title',
    titleTemplate: '%s | vue-meta Example App',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
    {   name:"msapplication-TileImage",  content:"https://admin.indiakhabar.co.in/images/posts/av.jpg"},
    {   property: 'og:site_name', content: 'India Khabar :: NewsPortal'},
    {   property: 'og:title', content: 'India Khabar :: '+this.post.title},
    {   property: 'og:description', content: 'Connect and follow'},
    {   property: 'og:image', content: 'https://admin.indiakhabar.co.in/images/posts/av.jpg'},
    {   property: 'og:type', content: 'website'},
    {   property: "og:image:type", content: 'image/jpeg'},
    {   property: "og:image:width", content:"300"},
    {   property:"og:image:height", content:"300"},
    {   property: 'og:url', content: 'https://indiakhabar.co.in'},
      
    ]
  },
  data(){
        return{
			PostID:this.$route.params.id,
            url: process.env.BASE_URL+'like/',
            post:[],
            relatedposts:[],
			profiles:[],
            paused:false,
            likes:'',
            dislikes:'',
            networks: [
                { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            ],
        }
    },
    // metaInfo () {
    //   return {
    //     title: this.post.title,
    //     meta: [
    //         { property: 'og:title', content: 'India Khabar :: '+this.post.title},
    //         { property: 'og:site_name', content: 'India Khabar :: NewsPortal'},
    //         { property: 'og:description', content: 'Connect and follow '},
    //         {   property: 'og:type', content: 'NewsPortal'},
    //         {   property: "og:image:alt", content: 'NewsPortal'},
    //         {   property: 'og:url', content: 'https://admin.indiakhabar.co.in/images/posts/av.jpg'},
    //         {   property: 'og:image', content: 'https://admin.indiakhabar.co.in/images/posts/av.jpg'}    
    //     ]
    //   }
    // },
    beforeRouteUpdate (to, from, next) {
    	this.PostID = to.params.id
		this.fetchData();
    next()
	},
    watch: {
        likes() {
            Mainaxios.get("api/countLikes/"+this.PostID).then(response => {
                [this.likes = response.data.likes, 
				this.dislikes = response.data.dislikes]
				
				})
        },
    },
    methods:{
        getResults(page = 1) {
        Mainaxios.get('api/banner?page=' + page)
            .then(response => {
                this.posts = response.data;
            });
        },
        async fetchData(){
            await Mainaxios.get("api/singlepost/"+this.PostID).then(response => {
				[this.post = response.data.news, 
				this.relatedposts = response.data.related]
				window.scrollTo({top: 0, behavior: 'smooth'});
				})
				.catch(err=>{
					const status =  err.response.status
					if(status==404){
						this.$router.push({name:'Missing'})
					}
				});
			await Mainaxios.get("api/profile").then(({data}) => (this.profiles = data));
        },
        
        async countLikes(){
            await Mainaxios.get("api/countLikes/"+this.PostID).then(response => {
                [this.likes = response.data.likes, 
				this.dislikes = response.data.dislikes]
				
				})
				
        },
        async like(){
            await Mainaxios.put("api/like/"+this.PostID).then(response => {
				this.countLikes();
				})
				
        },
        async dislike(){
            await Mainaxios.put("api/dislike/"+this.PostID).then(response => {
				this.countLikes();
				})
				
        },
        
    },
    created(){
		this.fetchData();
		this.countLikes();
		// window.scrollTo({top: 0, behavior: 'smooth'});
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
 .share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }
  a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
  }
  a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
  }
  a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }
  .like{
    cursor: pointer;
  }
</style>
