<template>
  <div id="app">
    <Navbar/>
    <router-view />
    <Footer-Component />
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
        return{
    
    }
  },
  created () {
    document.title = "Indian Khabar | News Portal";
    
  },
  methods:{
    
  }
};
</script>

<style scoped>

</style>

